//import background_image from './images/PXL_20220908_230450816.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="titleText">Malcolm Sterling Foundation</div>
      <div className="subText">est 12/2023</div>
    </div>
  );
}

export default App;
